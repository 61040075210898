import { useEffect, useState, useRef } from "react";
import { Modal, ModalTitle } from "react-bootstrap";
import PageHeader from "../components/common/PageHeader";
import { useNavigate, Link } from "react-router-dom";
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { FiExternalLink } from "react-icons/fi";
import notification from "../helpers/notification";
import ApiService from "../api/ApiService";
import ServiceCard from "../components/services/ServiceCard";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import ReCAPTCHA from "react-google-recaptcha";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import moment from 'moment';
import axios from "axios";
import { description } from "platform";
import Tooltip from '@mui/material/Tooltip';

const expiry = process.env.REACT_APP_OTPEXPIRY;
let timer = null;
const Services = () => {
  const scrollToElement = () => {
    const element = document.getElementById('scrollToAllPages');
    // console.log("elements",element);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  };

  const { loginPopup, setLoginPopup, token, setToken, user } =
    useContext(AppContext);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [sankirtan, setSankirtan] = useState(false);
  const [seniorPriest, setSeniorPriest] = useState(false);
  const [krishnasBounty, setKirishnasBounty] = useState(false);
  const [krishnasPrasad, setKirishnasPrasad] = useState(false);
  const [divine, setDivineGifts] = useState(false);
  const [services, setServices] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedService, setSelectedSerivce] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescp, setModalDescp] = useState("");
  const [modalImg, setModalImg] = useState("");
  const recaptchaRef = useRef();
  const [icsdatawl, setICSdatawl] = useState([])

  const [otpSent, setOtpSent] = useState(false);
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpExp, setOtpExp] = useState(expiry);
  const [resendtimes, setResendTimes] = useState(0);
  const [showresendlink, setShowResendLink] = useState(false);
  const [otpverify, setOtpVerify] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [showTimer, setShowTimer] = useState(true)

  const [email, setEmail] = useState([]);
  const [name, setName] = useState([]);
  const [address, setAddress] = useState([]);
  const [servicetitle, setTitle] = useState([]);
  const [discription, setDiscription] = useState([]);

  const [referredTime, setReferredTime] = useState([]);
  const [referredDate, setReferredDate] = useState([]);
  const [sizeOfGathering, setSizeOfGathering] = useState([]);
  const [showOccasionModal, setShowOccasionModal] = useState(false);




  const FetchIcsUser = async () => {
    const icsmob = localStorage.getItem('ics_number');
    //console.log(icsmob)

    const response = await ApiService.getIcsData(icsmob)

    // setICSdatawl(response.data);
    setICSdatawl([...response.data].reverse());
    //console.log(response.data[0].email.emailAddress);
    setName(response.data[0].name)
    setEmail(response.data[0].email.emailAddress)
    setAddress(response.data[0].address.line1)



  }

  function getTime(time) {
    return (
      Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
    );
  }


  function setCookie(name, value) {
    let expires = "";
    let date = new Date();
    date.setTime(date.getTime() + (1 * 60 * 1000));
    expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  const resendOtp = () => {
    setShowResendLink(false)

    if (getCookie("limitReached2")) {
      notification('warning', 'Resend OTP limit has been reached!')
      setResendTimes(0)
      setShowTimer(false)
    } else {
      if (resendtimes < 2) {
        setOtpExp(expiry)
        handleSendOtp()
        setResendTimes(prev => prev + 1)
      } else {
        setCookie("limitReached2", true)
        notification('warning', 'Resend OTP limit has been reached!')
        setShowTimer(false)
      }
    }
  }

  // const handleOccasionModalShow = () => {
  //   setShowOccasionModal(true);
  // };

  // const handleHideOccasionModal = () => {
  //   setShowOccasionModal(false);
  //   // Add any additional logic you need when hiding the modal
  // };


  const handleLogin = async () => {
    // alert(otp)
    if (otp.length >= 6) {
      try {
        let data = {
          number,
          otp,
        };

        ///ICS START///
        const response = await ApiService.getIcsData(number)

        // console.log(response.data[0]);
        localStorage.setItem('ics_id', response.data[0].id);
        localStorage.setItem('ics_name', response.data[0].name);
        localStorage.setItem('ics_number', response.data[0].number);

        ///ICS  END///

        const res = await ApiService.loginUser(data);
        //setICSdatawl([...res.data].reverse());
        setICSdatawl(response.data);
        // console.log(res)
        localStorage.setItem(`token`, res.token);
        setToken(res.token);
        setLoginPopup(false);
        notification("success", res.name + ' ' + res.message);
        setOtpSent(false);

        setOtp('')
      } catch (err) {
        // console.log(err);
        notification("error", err.response.data.message);
        localStorage.setItem('ics_id', '');
        localStorage.setItem('ics_name', '');
        localStorage.setItem('ics_number', '');
      }
    } else {
      notification("warning", 'Please Enter Valid OTP');
      localStorage.setItem('ics_id', '');
      localStorage.setItem('ics_name', '');
      localStorage.setItem('ics_number', '');
    }
  };

  const fetchServices = async () => {
    try {
      const res = await ApiService.fetchServices();
      setServices(res);
    } catch (error) {
      console.log(error);
      notification("error", "Unable to fetch Services!");
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);
  useEffect(() => {

    scrollToElement();
  }, [])


  const handleSendOtp = async (number) => {


    if (timer) {
      clearInterval(timer)
    }
    if (number.length >= 10) {
      try {
        setBtnDisabled(true)
        const res = await ApiService.sendOtpp({ number });
        notification("success", res.name + ' ' + res.message);
        setBtnDisabled(false)
        timer = setInterval(() => {
          setOtpExp(prev => prev - 1)
        }, 1000)

        setOtpSent(true);
      } catch (err) {
        console.log(err);
        if (err.response.status === 200) {
          otpSent(true)
        } else
          if (err.response.status === 400) {
            notification('warning', err.response.data.message)
          } else {
            notification('error', err.response.data.message)
          }
        setBtnDisabled(false)
      }
    } else {
      notification("warning", 'Please Enter Valid Number');
      setBtnDisabled(false)
    }
  };




  const CreateService = async (AllData) => {
    const ics_idd = localStorage.getItem('ics_id');
    const updatedText = selectedService.title.replace(/\|/g, '');

    let createServiceIcs = {
      icsId: AllData.idd,
      category: updatedText === 'Speak to a Senior Priest' ? 'Speak to a senior priest' : updatedText,
      description: `${AllData.specialNote ? AllData.specialNote : ''},${AllData.referredTime ? AllData.referredTime : ''},${AllData.referredDate ? AllData.referredDate : ''},${AllData.sizeOfGathering ? AllData.sizeOfGathering : ''}`,
      oper: 'add'
    }

    const responsee = await ApiService.createServicetoIcs(createServiceIcs)


    notification("success", responsee.message);
    setShowModal(false);
    window.location.reload(true)
  }

  const handleFormSubmitIcs = async (AllData) => {
    const ics_id = localStorage.getItem('ics_id');

    // Concatenate the values with commas
    const combinedValue = `${discription},${referredTime},${referredDate},${sizeOfGathering}`;


    let createIndividual = {
      lname: AllData.name,
      iname: '',
      gender: '',
      mobileNo: AllData.phone,
      email: AllData.email,
      panNo: '',
      dob: '',
      ma: '',
      address: AllData.address ? AllData.address : '',
      address2: '',
      address3: '',
      city: '',
      state: '',
      country: 'country',
      pincode: '',
      category: 'services',
      type: 'services',
      strictdq: 'no',
    }

    const responsee = await ApiService.createIndividualQuick(createIndividual)

    //

    //console.log(responsee.message);
    //console.log(responsee.icsid);




    if (responsee.icsid) {

      let bhdata = {
        idd: responsee.icsid,
        ...AllData
      }
      // CreateService(AllData)
      CreateService(bhdata)

      localStorage.setItem('ics_id', responsee.icsid);
      localStorage.setItem('ics_name', AllData.name);
      localStorage.setItem('ics_number', AllData.phone);

    }


  }






  const handleFormSubmit = async (data) => {
    try {
      const token = await recaptchaRef.current.executeAsync();

      let AllData = {
        cat_id: selectedService?.title[1],
        name: localStorage.getItem('ics_name'),
        type: "service",
        ...data,
      }



      setNumber(data.phone);
      setEmail(data.email);
      setName(data.name);
      setAddress(data.address);



      // console.log(token)
      data.token = token;
      const records = icsdatawl.length
      //console.log(records)


      if (!user) {
        handleSendOtp(data.phone);
        handleFormSubmitIcs(AllData);
      }

      if (user) {
        if (!icsdatawl) {
          handleFormSubmitIcs(AllData);

        } else {

          let bhdata = {
            idd: localStorage.getItem('ics_id'),
            ...AllData
          }
          CreateService(bhdata)


        }

        await ApiService.createEnquiry({
          service: selectedService?._id,
          type: "service",
          ...data,
        });


      }



      reset();
      recaptchaRef.current.reset();
      if (selectedService?.redirect) {
        navigate("/bhagavad-gita");
      }
    } catch (error) {
      console.log(error);
      notification("error", "Unable to submit form!");
    }
  };

  const handleHideModal = () => {
    setShowModal(false);
    reset();
  };

  const handleOccasionSave = async (data) => {
    console.log("occasionBook", data);
    try {
      //Send Whatsapp Message Here For Slot Booking
      const res = await ApiService.occasionBook(data);
      notification("success", "Hare Krishna! Your Puja has been Booked.");
      reset();
    } catch (err) {
      console.log(err);
      // notification("error", err.response.data.message);
    }
    setShowOccasionModal(false);
  };


  const [meta, setMeta] = useState(null);
  const fetchMeta = async (pagename) => {
    try {
      let res = await ApiService.fetchMetaData(pagename);
      setMeta(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      FetchIcsUser();
      reset({
        name: user.name,
        phone: user.phone,
        email: user.email,
        address: user.address,
      });
    }
  }, [user, name, email, address]);

  const getIcsData = async (e) => {
    const val = e.target.value.length;
    setNumber(e.target.value);
    // console.log(e.target.value)
    try {
      if (val === 10) {
        const res = await ApiService.getIcsData(e.target.value)
        //console.log(res.data.length)
        if (res.data.length > 0) {
          //console.log(e.target.value)
          setICSdatawl([...res.data].reverse());
          handleSendOtp(e.target.value);
        }


      } else {
        setICSdatawl('');

      }

    } catch (error) {
      console.log(error)
    }

  }

  useEffect(() => {
    fetchMeta("services");
  }, []);


  const handleSelectProfiles = async (e) => {


    const responseDataa = icsdatawl.filter(dat => dat.id == e.target.value);

    icsdatawl.filter(dat => dat.id == e.target.value).map((item) => (

      localStorage.setItem('ics_id', item.id),
      localStorage.setItem('ics_name', item.name),
      localStorage.setItem('ics_number', item.number)


    ))
    //console.log(responseDataa[0].number)
    reset({
      phone: responseDataa[0].number ? responseDataa[0].number.trim() : '',
      email: responseDataa[0].email ? responseDataa[0].email.emailAddress.trim() : '',
      // pan: responseDataa[0].pan ? responseDataa[0].pan.trim() : '',
      address: responseDataa[0].address.line1 ? responseDataa[0].address.line1.trim() : '',
      // pin: responseDataa[0].address.pincode ? responseDataa[0].address.pincode.trim() : '',
    });


  }


  const handelOther = (e) => {
    setICSdatawl('');
    reset({
      phone: localStorage.getItem('ics_number'),
      name: "",
      email: "",
      address: "",
    });
  }


  const handelOptions = async (e) => {
    if (token) {
      const res = await ApiService.getIcsData(localStorage.getItem('ics_number'))
      // setICSdatawl(res.data);
      setICSdatawl([...res.data].reverse());
    }
  }


  return (
    <>
      <MetaData title="Services - ISKCON of Attapur" />
      <PageHeader title="Services" imgSrc="/images/page-header/04.webp" />

      <section className="services-sec">
        <div className="container">
          <div className="row" id="scrollToAllPages">
            <div className="col-md-10 mx-auto">
              <div className="heading">
                <h2 className="head-1">BLISS OF BHAKTI</h2>
                <p>
                  Iskcon of Attapur welcomes devotees to the temple and also
                  offers various devotional services where the temple comes to
                  you, giving you the divine opportunity to welcome Krishna into
                  your personal spaces and your heart. Our endeavour is to
                  enhance your spiritual journey and to bless every aspect of
                  your being. Hare Krishna!
                </p>
              </div>
            </div>
          </div>
          <div className="line mx-auto"></div>
          <div className="books-wrap">
            <div className="row my-5">
              {services?.length === 0 ? (
                <>
                  <div className="col-lg-4 col-md-6 mb-5">
                    <div style={{ marginTop: "-5px" }}>
                      <Skeleton height={500} />
                    </div>
                    <div className="mx-1 mb-4 mt-3 pb-4">
                      <Skeleton width={200} className="mb-4" />
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                    <div style={{ marginTop: "-5px" }}>
                      <Skeleton height={500} />
                    </div>
                    <div className="mx-1 mb-4 mt-3 pb-4">
                      <Skeleton width={200} className="mb-4" />
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                    <div style={{ marginTop: "-5px" }}>
                      <Skeleton height={500} />
                    </div>
                    <div className="mx-1 mb-4 mt-3 pb-4">
                      <Skeleton width={200} className="mb-4" />
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                    <div style={{ marginTop: "-5px" }}>
                      <Skeleton height={500} />
                    </div>
                    <div className="mx-1 mb-4 mt-3 pb-4">
                      <Skeleton width={200} className="mb-4" />
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                    <div style={{ marginTop: "-5px" }}>
                      <Skeleton height={500} />
                    </div>
                    <div className="mx-1 mb-4 mt-3 pb-4">
                      <Skeleton width={200} className="mb-4" />
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {services.map((service, i) => (
                    <div key={i} className="col-lg-4 col-md-6 mb-5">
                      <ServiceCard
                        key={i}
                        setShowModal={setShowModal}
                        showModal={showModal}
                        service={service}
                        setSelectedSerivce={setSelectedSerivce}
                      />
                    </div>
                  ))}


                </>
              )}
              {/* <div className="book-card">
                <div className="col-lg-4 col-md-6 mb-5">
                  <figure>
                    <img
                      className="w-100"
                      src="/images/campaign/occasion.webp"
                      alt="Nitya Seva image"
                    />
                  </figure>
                  <h5>Book a Service/Puja on a your special occasion</h5>
                  <p>
                    Perform Puja at the temple on your special occasions where a special puja will be held in your name, following which, you could accompany the devotees for Annadaan distribution and have lunch prasad, at the temple, with your family.
                  </p>
                </div>
                <div className="book-btn">
                  <button
                    onClick={handleOccasionModalShow}
                    className="box-hover text-uppercase">
                    Request Booking
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      {/* <Modal centered show={showOccasionModal} onHide={handleHideOccasionModal}>
        <div className="modal-wrap">
          <button className="btn close-svg" onClick={handleHideOccasionModal}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
              ></path>
            </svg>
          </button>
          <div className="row">
            <div className="col-md-5 d-sm-block d-none">
              <figure>
                <img
                  className="w-100"
                  src={`/images/campaign/occasion1111.jpeg`}
                  alt="Campaign image"
                />
              </figure>
            </div>
            <div className="col-md-7">
              <div className="pop-up-form">
                <h2>Book a Service/Puja on a your special occasion</h2>
             

                <form
                  action=""
                  className="mt-3 mt-sm-0"
                  onSubmit={handleSubmit(handleOccasionSave)}
                >


                  <div className="row">

                    <div className="col-md-6">
                      <div className="label-input-wrap">
                        <label htmlFor="name" className="imp">Name</label>
                        <input
                          type="text"
                          id="name"
                          placeholder="Name"
                          {...register("name", { required: true })}
                          onKeyPress={(event) => {
                            if (/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors.name?.type === "required" && (
                          <span>Name is required</span>
                        )}

                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="label-input-wrap">
                        <label htmlFor="email">Email</label>
                        <input
                          type="text"
                          id="email"
                          placeholder="Email Id"
                          // disabled={user && icsdatawl ? true : false}
                          {...register("email", {
                            required: true, pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Please enter a valid email",
                            },
                          })}
                        />
                        {errors.email?.type === "required" && (
                          <small className="error">This is required</small>
                        )}
                        {errors.email && (
                          <small className="text-danger error">
                            {errors.email.message}
                          </small>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="label-input-wrap">
                        <label htmlFor="phone">Phone</label>
                        <input
                          type="text"
                          id="phone"
                          placeholder="Mobile Number"
                          disabled={user ? true : false}
                          maxLength={10}
                          onKeyUp={getIcsData}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          {...register("phone", {
                            required: true,
                            pattern: {
                              value: /^[6-9]\d*$/,
                              message: "Please provide valid phone number",
                            },
                            minLength: {
                              value: 10,
                              message: "Phone must be minimum 10 digit",
                            },
                          })}
                        />
                        {errors.phone?.type === "required" && (
                          <small className="error">This is required</small>
                        )}
                        {errors.phone && (
                          <small className="error">
                            {errors.phone.message}
                          </small>
                        )}
                      </div>
                    </div>





                    <div className="col-sm-6">
                      <div className="label-input-wrap">
                        <label htmlFor="">PAN </label>
                        <Tooltip title="For 80G reciept" placement="bottom-start">
                          <input
                            type="text"
                            style={{ textTransform: "uppercase" }}
                            name=""
                            id=""
                            // onKeyUp={fetchPanFromIcs}
                            maxLength={10}
                            placeholder="PAN"
                            {...register("pan", {
                              pattern: {
                                value: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
                                message: "Please enter valid PAN",
                              },
                            })}
                          />
                        </Tooltip>
                        {errors.pan?.type === "required" && (
                          <small className="error">This is required</small>
                        )}
                        {errors.pan && (
                          <small className="text-danger error">
                            {errors.pan.message}
                          </small>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="label-input-wrap">
                        <label htmlFor="address">Address</label>
                        <input
                          placeholder="Full Address"
                          type="text"
                          id="address"
                          {...register("address", { required: true })}
                        />
                        {errors.address?.type === "required" && (
                          <small className="error">This is required</small>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="label-input-wrap">
                        <label htmlFor="inNameOf">In Name </label>
                        <input
                          type="text"
                          placeholder="In Name Of"
                          {...register("inNameOf")}
                        />

                        {errors.inNameOf?.message && (
                          <small className="text-danger error">
                            {errors.inNameOf?.message}
                          </small>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="label-input-wrap">
                        <label htmlFor="relationwithDonor">
                          Relation
                        </label>
                        <input
                          type="text"
                          placeholder="Relation"
                          {...register("relationwithDonor")}
                        />

                        {errors.relationwithDonor?.message && (
                          <small className="text-danger error">
                            {errors.relationwithDonor?.message}
                          </small>
                        )}
                      </div>
                    </div>




                    <div className="col-sm-6">
                      <div className="label-input-wrap">
                        <label htmlFor="occasion">Occasion</label>
                        <select {...register("occasion")}>
                          <option value="birthday">
                            Select Occasion
                          </option>
                          <option value="Birthday">Birthday</option>
                          <option value="MarriageAnniversary">
                            Marriage Anniversary
                          </option>
                          <option value="DeathAnniversary">
                            Death Anniversary
                          </option>
                          <option value="OtherOccasion">
                            Any Other Occasion
                          </option>
                        </select>

                        {errors.occasion?.message && (
                          <small className="text-danger error">
                            {errors.occasion?.message}
                          </small>
                        )}
                      </div>
                    </div>


                    <div className="col-sm-6">
                      <div className="label-input-wrap">
                        <label htmlFor="inDate">Occasion Date</label>
                        <div className="input-date">
                          <input
                            type="date"

                            placeholder="Date"
                            {...register("inDate")}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <label htmlFor="">Message</label>
                    <textarea
                      type="text"
                      maxLength={250}
                      {...register("memoryText")}
                    ></textarea>
                    <small>* Minimum 250 characters allowed</small>

                    <button className="box-hover custom-btn" type="submit">Submit</button>

                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </Modal> */}

      {/* Talk to a priest */}
      <Modal centered show={showModal} onHide={handleHideModal}>
        <div className="modal-wrap">
          <button className="btn close-svg" onClick={handleHideModal}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
              ></path>
            </svg>
          </button>
          <div className="row">
            <div className="col-md-5 d-sm-block d-none">
              <figure>
                <img
                  src={`${process.env.REACT_APP_S3_URL}/uploads/service/${selectedService?.image}`}
                  alt=""
                />
              </figure>
            </div>
            <div className="col-md-7">
              <div className="pop-up-form">
                <div className="pop-up-top">
                  <div className="heading">
                    <h2 className="head-2">
                      {selectedService?.title.split("|")[0]}
                    </h2>
                    <h2 className="head-1">
                      {selectedService?.title.split("|")[1]}
                    </h2>
                  </div>

                  <figure className="hare-krishna-logo-fig">
                    <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                  </figure>
                </div>

                <p>{selectedService?.description}</p>
                {otpSent ? (
                  <div className="col-md-12 ps-md-0">
                    <div className="pop-up-form">
                      <div className="pop-up-top">
                        <div className="heading">
                          <h2 className="head-2">OTP Verification</h2>
                        </div>
                      </div>
                      <p>Enter the OTP sent to +91 {number}</p>
                      <form action="" className="mt-3 mt-sm-0">
                        <div className="row align-items-center">
                          <div className="col-12">
                            <div className="label-input-wrap">
                              <input
                                type="tel"
                                id="otp"
                                name="otp"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                onKeyPress={(e) => {
                                  if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                minLength={6}
                                maxLength={6}
                                placeholder="Enter OTP"
                              />
                            </div>
                            {
                              (!showresendlink && showTimer) &&
                              <div className="time-wrap">
                                <p className="mb-0">Resend OTP in</p>
                                <span className="timer_span d-inline-block">{getTime(otpExp)}s</span>
                              </div>
                            }
                          </div>
                        </div>
                        <div className="pop-form-btn">
                          <button
                            className="box-hover"
                            type="button"
                            onClick={handleLogin}
                          >
                            Verify
                          </button>
                        </div>
                        {
                          showresendlink &&
                          <div className="time-wrap mt-4">
                            <p className="mb-0">Not yet received OTP?</p>
                            <button onClick={resendOtp} type='button'>Resend OTP</button>
                          </div>
                        }
                      </form>
                    </div>
                  </div>
                ) : (

                  <form
                    action=""
                    className="mt-3 mt-sm-0"
                    onSubmit={handleSubmit(handleFormSubmit)}
                  >
                    <div className="row">
                      {selectedService?.inputAddOns?.includes(
                        "Name of Child"
                      ) && (
                          <div className="col-sm-6">
                            <div className="label-input-wrap">
                              <label htmlFor="nameOfChild">Name of Child</label>
                              <input
                                type="text"
                                id="nameOfChild"
                                {...register("nameOfChild", { required: true })}
                              />
                              {errors.nameOfChild?.type === "required" && (
                                <small className="error">This is required</small>
                              )}
                            </div>
                          </div>
                        )}
                      {selectedService?.inputAddOns?.includes("Age of Child") && (
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label htmlFor="ageOfChild">Age of Child</label>
                            <input
                              type="text"
                              id="ageOfChild"
                              {...register("ageOfChild", { required: true })}
                            />
                            {errors.ageOfChild?.type === "required" && (
                              <small className="error">This is required</small>
                            )}
                          </div>
                        </div>
                      )}
                      {selectedService?.inputAddOns?.includes(
                        "Name of Parent"
                      ) && (
                          <div className="col-sm-6">
                            <div className="label-input-wrap">
                              <label htmlFor="nameOfParent">Name Of Parent</label>
                              <input
                                type="text"
                                id="nameOfParent"
                                {...register("nameOfParent", { required: true })}
                              />
                              {errors.nameOfParent?.type === "required" && (
                                <small className="error">This is required</small>
                              )}
                            </div>
                          </div>
                        )}
                      {user && icsdatawl ?

                        selectedService?.inputAddOns.includes("Name") && (

                          <div className="col-sm-6">
                            <div className="label-input-wrap">
                              <label htmlFor="">Select Name</label>
                              <select
                                {...register("name", {
                                  required: true,
                                  onChange: (e) => {
                                    handleSelectProfiles(e);
                                  },
                                })}
                              >
                                {icsdatawl.map((data, i) => (
                                  <option key={i} value={data.id}>
                                    {data.name}
                                  </option>
                                ))}

                              </select>
                              {errors.name?.type === "required" && (
                                <small className="error">This is required</small>
                              )}
                              <Link
                                className="donation-link"
                                onClick={handelOther}
                              // style={{fontSize:'18px'}}
                              >
                                To enter new name click here
                              </Link>
                            </div>
                          </div>
                        )

                        :

                        selectedService?.inputAddOns.includes("Name") && (

                          <div className="col-sm-6">
                            <div className="label-input-wrap">
                              <label htmlFor="name">Name</label>
                              <input
                                type="text"
                                id="name"
                                {...register("name", { required: true })}
                              />
                              {errors.name?.type === "required" && (
                                <small className="error">This is required</small>
                              )}
                              {token ?
                                <Link
                                  className="donation-link"
                                  onClick={handelOptions}
                                >
                                  Select Options
                                </Link>
                                : ''}
                            </div>
                          </div>

                        )}
                      {selectedService?.inputAddOns.includes("Email") && (
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label htmlFor="email">Email</label>
                            <input
                              type="text"
                              id="email"
                              // disabled={user && icsdatawl ? true : false}
                              {...register("email", {
                                required: true, pattern: {
                                  value:
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message: "Please enter a valid email",
                                },
                              })}
                            />
                            {errors.email?.type === "required" && (
                              <small className="error">This is required</small>
                            )}
                            {errors.email && (
                              <small className="text-danger error">
                                {errors.email.message}
                              </small>
                            )}
                          </div>
                        </div>
                      )}
                      {selectedService?.inputAddOns.includes("Phone") && (
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label htmlFor="phone">Phone</label>
                            <input
                              type="text"
                              id="phone"
                              disabled={user ? true : false}
                              maxLength={10}
                              onKeyUp={getIcsData}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              {...register("phone", {
                                required: true,
                                pattern: {
                                  value: /^[6-9]\d*$/,
                                  message: "Please provide valid phone number",
                                },
                                minLength: {
                                  value: 10,
                                  message: "Phone must be minimum 10 digit",
                                },
                              })}
                            />
                            {errors.phone?.type === "required" && (
                              <small className="error">This is required</small>
                            )}
                            {errors.phone && (
                              <small className="error">
                                {errors.phone.message}
                              </small>
                            )}
                          </div>
                        </div>
                      )}
                      {selectedService?.inputAddOns.includes("Location") && (
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label htmlFor="location">Location</label>
                            {/* <select id="location" {...register("location",{required:true})}>
                              <option value="">Select location</option>
                              <option value="Bhiwandi">Bhiwandi</option>
                              <option value="Thane">Thane</option>
                              <option  value="Mumbai">Mumbai</option>
                             </select> */}
                            <input
                              type="text"
                              id="location"
                              {...register("location", { required: true })}
                            />
                            {errors.location?.type === "required" && (
                              <small className="error">This is required</small>
                            )}
                          </div>
                        </div>
                      )}
                      {selectedService?.inputAddOns.includes("Address") && (
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label htmlFor="address">Address</label>
                            <input
                              type="text"
                              id="address"
                              {...register("address", { required: true })}
                            />
                            {errors.address?.type === "required" && (
                              <small className="error">This is required</small>
                            )}
                          </div>
                        </div>
                      )}
                      {selectedService?.inputAddOns.includes(
                        "No. of singers"
                      ) && (
                          <div className="col-sm-6">
                            <div className="label-input-wrap">
                              <label htmlFor="noOfSingers">No of singers</label>
                              <input
                                type="text"
                                id="noOfSingers"
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                {...register("noOfSingers", {
                                  required: true,
                                  pattern: {
                                    value: /^\d+$/,
                                    message: "No of singers must be number",
                                  },
                                })}
                              />
                              {errors.noOfSingers?.type === "required" && (
                                <small className="error">This is required</small>
                              )}
                              {errors.noOfSingers && (
                                <small className="error">
                                  {errors.noOfSingers.message}
                                </small>
                              )}
                            </div>
                          </div>
                        )}
                      {selectedService?.inputAddOns.includes(
                        "Size of gathering"
                      ) && (
                          <div className="col-sm-6">
                            <div className="label-input-wrap">
                              <label htmlFor="sizeOfGathering">
                                Size of Gathering
                              </label>
                              <input
                                type="text"
                                id="sizeOfGathering"
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                {...register("sizeOfGathering", {
                                  required: true,
                                  pattern: {
                                    value: /^\d+$/,
                                    message: "Size of gathering must be number",
                                  },
                                })}
                              />
                              {errors.sizeOfGathering?.type === "required" && (
                                <small className="error">This is required</small>
                              )}
                              {errors.sizeOfGathering && (
                                <small className="error">
                                  {errors.sizeOfGathering.message}
                                </small>
                              )}
                            </div>
                          </div>
                        )}
                      {selectedService?.inputAddOns.includes(
                        "Preferred Time"
                      ) && (
                          <div className="col-sm-6">
                            <div className="label-input-wrap">
                              <label htmlFor="referredTime">Preferred Time</label>
                              <input
                                type="time"
                                id="referredTime"
                                {...register("referredTime", { required: true })}
                              />
                              {errors.referredTime?.type === "required" && (
                                <small className="error">This is required</small>
                              )}
                              {errors.referredTime && (
                                <small className="error">
                                  {errors.referredTime.message}
                                </small>
                              )}
                            </div>
                          </div>
                        )}
                      {selectedService?.inputAddOns.includes(
                        "Preferred Date"
                      ) && (
                          <div className="col-sm-6">
                            <div className="label-input-wrap">
                              <label htmlFor="referredDate">Preferred Date</label>
                              <input
                                type="date"
                                id="referredDate"
                                min={moment(Date.now()).format('YYYY-MM-DD')}
                                {...register("referredDate", { required: true })}
                              />
                              {errors.referredDate?.type === "required" && (
                                <small className="error">This is required</small>
                              )}
                              {errors.referredDate && (
                                <small className="error">
                                  {errors.referredDate.message}
                                </small>
                              )}
                            </div>
                          </div>
                        )}
                      {selectedService?.inputAddOns.includes("Special Note") && (
                        <div className="col-12">
                          <div className="label-input-wrap">
                            <label htmlFor="specialNote">Special Note</label>
                            <textarea
                              type="text"
                              id="specialNote"
                              {...register("specialNote")}
                            />
                            {/* {errors.specialNote?.type === 'required' && <small className="error">This is required</small> } */}
                          </div>
                        </div>
                      )}
                      {selectedService?.inputAddOns?.includes("Course Note") && (
                        <div className="col-12">
                          <div className="label-input-wrap">
                            {/* <label htmlFor="courseNote"> What inspired you to register for the "{selectedCourse?.title.split("|")[0]} {selectedCourse?.title.split("|")[1]}"?</label> */}
                            <label htmlFor="courseNote">
                              {" "}
                              What are you seeking to gain from this course?
                            </label>
                            <textarea
                              type="text"
                              id="courseNote"
                              {...register("courseNote", { required: true })}
                            />
                            {errors.courseNote?.type === "required" && (
                              <small className="error">This is required</small>
                            )}
                          </div>
                        </div>
                      )}
                      {selectedService?.inputAddOns?.includes("Session Note") && (
                        <div className="col-12">
                          <div className="label-input-wrap">
                            {/* <label htmlFor="courseNote"> What inspired you to register for the "{selectedCourse?.title.split("|")[0]} {selectedCourse?.title.split("|")[1]}"?</label> */}
                            <label htmlFor="sessionNote">
                              What are you seeking to gain from this session?
                            </label>
                            <textarea
                              type="text"
                              id="sessionNote"
                              {...register("sessionNote", { required: true })}
                            />
                            {errors.sessionNote?.type === "required" && (
                              <small className="error">This is required</small>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="col-12">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                          ref={recaptchaRef}
                          size="invisible"
                        />
                      </div>
                    </div>
                    <div className="pop-form-btn">
                      <button className="box-hover">
                        Submit
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 448 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                        </svg>
                      </button>
                    </div>
                  </form>

                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <GoToTop />
    </>
  );
};

export default Services;
