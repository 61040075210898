import { FaFacebookF } from 'react-icons/fa'
import { BsYoutube } from 'react-icons/bs'
import { FiInstagram } from 'react-icons/fi'
import { SiGmail } from 'react-icons/si'
import { Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WhatsAppLink from '../../helpers/WhatsAppLink'
import ApiService from "../../api/ApiService";
import notification from "../../helpers/notification";
import { useEffect, useState } from 'react'
const Footer = () => {

    const [schedule, setSchedule] = useState([]);


    const fetchSheduleData = async () => {
        try {
            const res = await ApiService.fetchSheduleData();
            setSchedule(res.sheduleds);

           // console.log(res);

        } catch (err) {
            console.log(err);
            notification("error", err.response.data.message);
        }
    };

    useEffect(() => {
        fetchSheduleData();
    }, []);

    return (
        <>
            <footer>
                <div className="container-fluid px-0">
                    <div className="row m-0">
                        <div className="col-lg-4 px-0 order-lg-1 ">
                            <div className="footer-content always-dark">
                                <h5>AARTIS AND DISCOURSES</h5>
                                <div className="footer-details">
                                    <p>
                                        Mangal Aarti - 04:30 am <br />
                                        Sringar Darshan - 07:30 am <br />
                                        Srimad Bhagavatam Discourse - 08:00 am <br />
                                        Raj Bhoga Aarti - 12:30 pm <br />
                                        Uthapana Aarti - 04:30 pm <br />
                                        Sandhya Aarti - 07:00 pm <br />
                                        Bhagavad Gita Discourse - 07:30 pm
                                    </p>
                                </div>
                                <div className="footer-details">
                                    <h6>Darshan Timings</h6>
                                    <p>
                                        04:30 am to 01:00 pm <br />
                                        04:30 pm to 9:00 pm
                                        <span className="inner-para d-block">
                                            Temple timings may be changed on special occasions.
                                        </span>
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 order-lg-2 order-3">
                            <div className="footer-content first-dark">

                                <a href="/about-us/#founder">

                                    <figure className="mb-4 footer-img">
                                        <img className="w-100" src="/images/footer/footer-img.png" alt="" />
                                    </figure>
                                </a>
                                <div className="footer-details">
                                    <h6>ISKCON ATTAPUR</h6>
                                    <a target="_blank" href="https://goo.gl/maps/pZ4VfCPf7nHWFbwh6"> <p>Plot no 220, MIG Road No 34, <br />
                                        HUDA Colony,<br></br>Mushk Mahal Residential complex,<br></br> near TRS office,  Attapur, Hyderabad,<br></br> Rangareddy district, Telangana 500048 </p>
                                    </a>
                                </div>
                                <p>
                                    <span className="key" style={{ fontWeight: 'bold' }}>Phone:</span>

                                    <a target="_blank" href="tel:7983748776" className="value" style={{ color: 'black' }}>

                                        &nbsp;+91-7983748776
                                    </a>
                                    <a target="_blank" href="tel:6309099908" className="value" style={{ color: 'black' }}>

                                        &nbsp;+91-6309099908
                                    </a>
                                </p>
                                <p>
                                    <span className="key" style={{ fontWeight: 'bold' }}>WhatsApp:</span>

                                    <a target="_blank" href="https://api.whatsapp.com/send?phone=917983748776&text&app_absent=0" className="value" style={{ color: 'black' }}>

                                        &nbsp;+91-7983748776
                                    </a>  <a target="_blank" href="https://api.whatsapp.com/send?phone=916309099908&text&app_absent=0" className="value" style={{ color: 'black' }}>

                                        &nbsp;+91-6309099908
                                    </a>
                                </p>
                                <p>
                                    <span className="key" style={{ fontWeight: 'bold' }}>Govinda's Restaurant:</span>

                                    <a href="tel:9392038004" className="value" style={{ color: 'black' }}>

                                        &nbsp;+91-9392038004
                                    </a>
                                </p>
                                <h5 className="mb-4">FOLLOW US</h5>
                                <div className="social-links">
                                    <a target="_blank" href="https://www.facebook.com/iskconattapurhyderabad/"><FaFacebookF /></a>
                                    <a target="_blank" href="https://www.youtube.com/@ISKCONATTAPUR/videos"><BsYoutube /></a>
                                    <a target="_blank" href="https://www.instagram.com/iskconattapur_hyderabad/"><FiInstagram /></a>
                                </div>
                                <p className="inner-para text-center mt-4">
                                    Dedicated to His Divine Grace A.C. Bhaktivedanta Swami Prabhupada Founder Acharya of the International Society for Krishna Consciousness.
                                </p>
                                <p className="mt-2 mb-0">
                                    &copy; {new Date().getFullYear()} ISKCON of Attapur <span className='d-inline-block mx-2'>|</span> All Rights Reserved.
                                </p>
                                <Link className='sitemap-link' to="/sitemap">SITEMAP</Link>
                            </div>
                        </div>
                        <div className="col-lg-4 px-0 order-lg-3 order-2">
                            <div className="footer-content second-dark">
                                <h5>SUNDAY SCHEDULE</h5>
                                {/* <div className="footer-details">
                                    <p>
                                        Congregation class:<br />
                                        11:00 am - 12.30 pm<br />

                                        Sunday youth class:<br />
                                        05:00 pm - 07.00 pm<br />

                                        Raj Bhoga Aarti and Kirtan:<br />
                                        12.30 pm - 01.00 pm<br />

                                        Lunch Prasadam for all:<br />
                                        01:00 pm - 2.30 pm<br />
                                    </p>

                                </div> */}
                                <div className="footer-details">
                                    {schedule.map((item) => (
                                        <p key={item._id}>
                                            {item.className_a}:<br />
                                            {item.from_a} - {item.to_a}
                                        </p>
                                    ))}
                                </div>

                                <figure className="international-logo">
                                    <img src="/images/footer/international-logo.jpg" alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <ToastContainer />
            <WhatsAppLink />
        </>
    )
}

export default Footer